import React from "react"
import Img from "gatsby-image"
//import { ImageGalleryWrapper } from './styles'
import ImageThumbnail from "./ImageThumbnail"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ImageGalleryWrapper = styled.section`
  > div:first-child {
    /* border: 1px solid #eee;
        border-radius: 5px; */
  }
  > div:last-child {
    margin-top: 5px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 5px;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

export default function ProductImageGallery({ selectedVariantImageId, images }) {
    const [activeImageThumbnail, setActiveImageThumbnail] = React.useState(
        images.find(({ id }) => id === selectedVariantImageId) || images[0]
    );

    React.useEffect(() => {
      setActiveImageThumbnail(
      images.find(({ id }) => id === selectedVariantImageId) || images[0]
      )
    }, [selectedVariantImageId, images, setActiveImageThumbnail]
    )

    const handleClick = image => {
        setActiveImageThumbnail(image)
    }
console.log(activeImageThumbnail)
  return (
    <ImageGalleryWrapper>
      <div>
        {activeImageThumbnail.localFile && (
          <GatsbyImage image={getImage(activeImageThumbnail.localFile)} lassName="d-block m-auto"/>
        //   <Img
        //   fluid={activeImageThumbnail.localFile?.childImageSharp.fluid}
        //   style={{ maxWidth: "400px" }}
        //   className="d-block m-auto"
        // />
        )}
      </div>
      <div>
        {images.map(image => {
          return (
            <ImageThumbnail
              key={image.id}
              onClick={handleClick}
              image={image}
              isActive={activeImageThumbnail.id === image.id}
            />
          )
        })}
      </div>
    </ImageGalleryWrapper>
  )
}
